import React from 'react';
import {graphql } from 'gatsby';
import { format } from 'date-fns';
import { nl, enUS } from 'date-fns/locale'
import { LanguageLayout } from '../containers/layouts';
import Wrapper from './../components/wrapper';
import WrapperContent from '../components/wrapperContent';
import ArticleHeader from './../containers/articleHeader';
import FullImage from './../components/fullImage/';
import Content from './../components/content/';
import SocialBar from './../components/socialBar';

export const NewsDetailTemplate = ({ title, slug, date, locale, fullImage, content }) => (
    <article>
        <ArticleHeader
            title={title}
            titleMeta={format(new Date(date), 'EEEE d MMMM yyyy', { locale: (locale === 'nl') ? nl : enUS })}
        />
        {fullImage &&
        <Wrapper fullWidth={true}>
            <FullImage src={fullImage} />
        </Wrapper>
        }
        <Wrapper>
            <WrapperContent>
                <Content html={content} />
                <SocialBar slug={slug} messageID="socialBar.news.socialMessage" pageTitle={title} />
            </WrapperContent>
        </Wrapper>
    </article>
);

const NewsDetails = (props) => {
    const { frontmatter , fields} = props.data.page.edges[0].node;
    const fullImage = (frontmatter && frontmatter.fullImage) ? frontmatter.fullImage.childImageSharp.fluid : null;

    return (
        <LanguageLayout location={props.location}>
            <NewsDetailTemplate
                title={frontmatter.title}
                date={frontmatter.date}
                fullImage={fullImage}
                slug={fields.slug}
                locale={props.pageContext.langKey}
                content={fields.frontmattermd.bodyText.html}
            />
        </LanguageLayout>
    )
};

export default NewsDetails;

export const newsDetailsQuery = graphql`
query NewsBySlug($slug:String!) {
  page: allMarkdownRemark(filter: {frontmatter: {templateKey: {eq: "news-page"}}, fields: {slug: {eq: $slug}}}) {
    edges {
      node {
        id
        frontmatter {
          title
          date
          fullImage {
            publicURL
            childImageSharp {
              fluid(maxWidth: 700) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
        fields {
          slug
          frontmattermd {
            bodyText {
                html
            }
          }
        }
      }
    }
  }
}
`;
